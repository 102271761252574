import React, { useState } from "react";
import { FaRegCopy } from "react-icons/fa";

import { css } from "@emotion/react";
import { RotateLoader } from "react-spinners";

import api from "../api/config";

import Container from "../components/Container";

const MainPage = () => {
  const [message, setMessage] = useState("");
  const [urlId, setUrlId] = useState("");
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const letsEncrypt = async (e) => {
    e.preventDefault();

    // Validate that message is not empty
    if (!message.trim()) {
      setError("Please enter a message to encrypt");
      return;
    }

    // Clear any previous errors
    setError("");

    const data = { message, urlId, url };

    try {
      setDeleted(false);
      setCopied(false);
      setLoading(true);
      const response = await api.post("encrypt", data);
      setLoading(false);

      setUrlId(response.data.urlId);
      setUrl(response.data.url);

      setMessage("");
    } catch (error) {
      setLoading(false);
      setError("Failed to encrypt message. Please try again.");
      console.error(error);
    }
  };

  const handleDelete = async () => {
    await api
      .delete(`encrypt/${urlId}`)
      .then((res) => {
        setDeleted(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => setCopied(true))
      .catch((error) => console.error("Error copying text: ", error));
  };

  return (
    <Container>
      <section className="min-h-screen py-12 px-4 bg-gradient-to-b from-slate-800 to-slate-900">
        <div className="max-w-5xl mx-auto">
          {/* Hero Section with SEO-friendly headings */}
          <div className="text-center mb-10">
            <h1 className="text-4xl md:text-4xl font-bold text-white mb-6 leading-tight">
              <span className="text-sky-400">Encrypted Message Links</span> - Secure Communication Made Simple
            </h1>
            <h2 className="text-xl md:text-base text-gray-300 max-w-3xl mx-auto leading-relaxed">
              Share encrypted messages with self-destructing links that automatically vanish after being read. Perfect for passwords, sensitive notes, and confidential information.
            </h2>
          </div>

          {/* Message Creator Card */}
          <div className="bg-gradient-to-r from-slate-700 to-slate-600 rounded-xl shadow-2xl overflow-hidden">
            {/* Card Header */}
            <div className="p-6 border-b border-slate-500">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-2xl md:text-2xl font-bold text-white">Create Your Encrypted Message Link</h3>
                  <p className="text-gray-300 mt-2">
                    Enter your message below, encrypt it, and get a secure one-time link to share
                  </p>
                </div>
                <div className="hidden md:block">
                  <div className="bg-green-100 text-green-800 px-4 py-1 rounded-full text-sm font-medium">
                    End-to-End Encrypted
                  </div>
                </div>
              </div>
            </div>

            {/* Card Body */}
            <div className="p-6">
              <form
                  className="space-y-6"
                  onSubmit={letsEncrypt}
              >
                {/* Security badges - improved visibility for SEO terms */}
                <div className="flex flex-wrap gap-2 mb-4 md:hidden">
                  <span className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-xs">End-to-End Encrypted</span>
                  <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-xs">Self-Destructing Links</span>
                  <span className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-xs">Zero Storage</span>
                </div>

                {/* Text area with improved styling */}
                <div className="relative">
            <textarea
                onChange={(e) => setMessage(e.target.value)}
                rows={6}
                value={message}
                className={`w-full rounded-lg border-2 ${error ? 'border-red-400' : 'border-slate-400'} bg-slate-800 text-white p-4 focus:border-sky-400 focus:ring focus:ring-sky-300 focus:ring-opacity-40 transition-all`}
                name="message"
                id="message"
                placeholder="Type your confidential message here..."
            ></textarea>
                  {error && (
                      <p className="text-red-400 text-sm mt-2 ml-1">{error}</p>
                  )}

                  {/* Security indicators to reinforce the encrypted nature */}
                  <div className="hidden md:flex absolute -top-3 right-4 gap-2">
                    <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-xs">Self-Destructing</span>
                    <span className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-xs">Zero Storage</span>
                  </div>
                </div>

                {/* Loading indicator */}
                {loading && (
                    <div className="flex justify-center py-6">
                      <RotateLoader
                          css={css`
                  display: block;
                  margin: 0 auto;
                `}
                          size={12}
                          color={"#0ea5e9"}
                          loading={loading}
                      />
                    </div>
                )}

                {/* Encrypt button with improved styling */}
                <div className="flex justify-center">
                  <button
                      onCopy={() => {
                        setCopied(true);
                      }}
                      className="bg-sky-500 hover:bg-sky-600 rounded-full py-3 px-8 text-white font-medium text-lg w-80 transition-all duration-300 flex items-center justify-center gap-2 shadow-lg"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                    </svg>
                    Create Encrypted Link
                  </button>
                </div>
              </form>

              {/* Result section */}
              {url && (
                  <div className="mt-8 pt-6 border-t border-slate-500">
                    <h4 className="text-white text-lg mb-4">Your encrypted message link is ready to share:</h4>

                    {/* URL display and copy */}
                    <div className="flex flex-col md:flex-row gap-4 items-center">
                      <div className="bg-slate-800 text-sky-400 px-4 py-3 rounded-lg flex-grow break-all border border-slate-600">
                        {url}
                      </div>
                      <button
                          onClick={handleCopyToClipboard}
                          className="bg-white hover:bg-gray-100 text-slate-800 px-6 py-3 rounded-lg font-medium flex items-center gap-2 transition-colors"
                      >
                        <FaRegCopy className="text-xl" />
                        {copied ? "Copied!" : "Copy Link"}
                      </button>
                    </div>

                    {/* Destroy option */}
                    <div className="mt-8 flex flex-col items-center">
                      <p className="text-gray-400 mb-4 max-w-lg text-center">
                        Need to revoke access? You can manually destroy this encrypted message now.
                      </p>
                      <button
                          onClick={handleDelete}
                          className="bg-red-700 hover:bg-red-800 rounded-full py-3 px-6 text-white font-medium w-64 transition-colors duration-300 flex items-center justify-center gap-2"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        Destroy Message Now
                      </button>
                      {deleted && (
                          <div className="mt-4 bg-red-900 text-white px-6 py-2 rounded-lg">
                            Message Successfully Deleted
                          </div>
                      )}
                    </div>
                  </div>
              )}
            </div>

            {/* Card Footer - Security Features */}
            <div className="bg-slate-800 p-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="flex items-start gap-3">
                  <div className="bg-sky-100 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-sky-700" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div>
                    <h5 className="text-white font-medium">End-to-End Encryption</h5>
                    <p className="text-gray-400 text-sm">Messages are encrypted before leaving your device</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="bg-red-100 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-700" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div>
                    <h5 className="text-white font-medium">Self-Destructing</h5>
                    <p className="text-gray-400 text-sm">Messages are permanently deleted after being read</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="bg-green-100 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-700" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                    </svg>
                  </div>
                  <div>
                    <h5 className="text-white font-medium">Free Service</h5>
                    <p className="text-gray-400 text-sm">No sign-up or payment needed for secure communication</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Trust indicators */}
          <div className="mt-12 text-center">
            <p className="text-gray-400 mb-6">Trusted by thousands for secure encrypted message sharing</p>
            <div className="flex justify-center gap-8 flex-wrap">
              <div className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <span className="text-white">AES-256 Encryption</span>
              </div>
              <div className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <span className="text-white">HTTPS Secure</span>
              </div>
              <div className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <span className="text-white">No Message Storage</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Main container with improved spacing and background */}
      <section className="max-w-6xl mx-auto bg-gradient-to-b from-slate-50 to-slate-100 rounded-xl shadow-lg my-8 p-6">
        <h1 className="text-4xl font-bold text-center p-6 text-slate-800 mb-8">
          Why Use Encrypted Self-Destructing Messages?
        </h1>

        {/* Benefits Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-slate-800 mb-6 border-b-2 border-slate-300 pb-2">
            Key Benefits of Encrypted Communication
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-slate-700 text-white rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-bold mb-3 text-sky-300">Confidentiality</h3>
              <p>Encryption ensures your messages can only be read by the intended recipient, protecting sensitive information.</p>
            </div>
            <div className="bg-slate-700 text-white rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-bold mb-3 text-sky-300">Security</h3>
              <p>Messages are encoded in a way that can only be decrypted by authorized recipients, adding an extra layer of protection.</p>
            </div>
            <div className="bg-slate-700 text-white rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-bold mb-3 text-sky-300">Privacy</h3>
              <p>Protect both sender and recipient privacy by ensuring messages cannot be intercepted or read by unauthorized individuals.</p>
            </div>
            <div className="bg-slate-700 text-white rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-bold mb-3 text-sky-300">Authenticity</h3>
              <p>Verify the authenticity of messages by confirming they were sent by the intended sender.</p>
            </div>
            <div className="bg-slate-700 text-white rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-bold mb-3 text-sky-300">Integrity</h3>
              <p>Ensure messages have not been tampered with or altered during transmission.</p>
            </div>
            <div className="bg-slate-700 text-white rounded-lg p-6 shadow-md hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-bold mb-3 text-sky-300">One-Time Access</h3>
              <p>Self-destructing messages can only be viewed once, preventing any potential leaks of sensitive information.</p>
            </div>
          </div>
        </div>

        {/* How Safe Whisper Works Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-slate-800 mb-6 border-b-2 border-slate-300 pb-2">
            How Safe Whisper Works
          </h2>
          <div className="flex flex-col md:flex-row gap-8 items-center">
            <div className="md:w-1/2">
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-xl font-bold mb-4 text-slate-800">Simple Three-Step Process</h3>
                <ol className="space-y-4">
                  <li className="flex items-start">
                    <span className="bg-sky-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3 shrink-0">1</span>
                    <div>
                      <p className="font-semibold">Write</p>
                      <p className="text-slate-600">Input your confidential information into Safe Whisper's secure platform.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-sky-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3 shrink-0">2</span>
                    <div>
                      <p className="font-semibold">Share</p>
                      <p className="text-slate-600">Generate a secure one-time secret link and share it with your intended recipient.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-sky-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3 shrink-0">3</span>
                    <div>
                      <p className="font-semibold">Burn</p>
                      <p className="text-slate-600">Once accessed, the information is permanently deleted from the system.</p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="bg-slate-800 text-white rounded-lg p-6">
                <h3 className="text-xl font-bold mb-4 text-sky-300">Advanced Security Features</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>End-to-end encryption using AES-256-GCM</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Zero Trust Architecture</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>HTTPS secure connections</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Open-source code for transparency</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Customizable expiration times</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>Optional password protection</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Use Cases Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-slate-800 mb-6 border-b-2 border-slate-300 pb-2">
            Common Use Cases
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-sky-500">
              <h3 className="text-lg font-bold mb-2 text-slate-800">Passwords & Credentials</h3>
              <p className="text-slate-600">Securely share login information without leaving traces in emails or messages.</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-sky-500">
              <h3 className="text-lg font-bold mb-2 text-slate-800">Financial Information</h3>
              <p className="text-slate-600">Share credit card details, banking information, and payment data securely.</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-sky-500">
              <h3 className="text-lg font-bold mb-2 text-slate-800">Legal Documents</h3>
              <p className="text-slate-600">Transmit confidential legal information with enhanced security.</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-sky-500">
              <h3 className="text-lg font-bold mb-2 text-slate-800">Software License Keys</h3>
              <p className="text-slate-600">Share license keys and activation codes without risk of unauthorized access.</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-sky-500">
              <h3 className="text-lg font-bold mb-2 text-slate-800">2FA Recovery Codes</h3>
              <p className="text-slate-600">Securely share two-factor authentication recovery codes.</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 border-l-4 border-sky-500">
              <h3 className="text-lg font-bold mb-2 text-slate-800">Sensitive Files</h3>
              <p className="text-slate-600">Upload and share confidential documents with self-destructing links.</p>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-slate-800 mb-6 border-b-2 border-slate-300 pb-2">
            Safe Whisper's Key Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-br from-slate-700 to-slate-800 text-white rounded-lg p-6 shadow-lg">
              <h3 className="text-xl font-bold mb-4 text-sky-300">Security First Design</h3>
              <p className="mb-4">Safe Whisper's commitment to security is evident through its robust encryption protocols and adherence to data protection best practices.</p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                  </svg>
                  <span>Client-side encryption ensures your data is protected even before transmission</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                  </svg>
                  <span>Encryption keys are never stored on servers, only in the URL</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                  </svg>
                  <span>Even Safe Whisper staff cannot read your secrets</span>
                </li>
              </ul>
            </div>
            <div className="bg-gradient-to-br from-slate-700 to-slate-800 text-white rounded-lg p-6 shadow-lg">
              <h3 className="text-xl font-bold mb-4 text-sky-300">User Experience</h3>
              <p className="mb-4">Safe Whisper provides a straightforward process for sharing secrets that anyone can use without technical expertise.</p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Notification options when secrets are read</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Customizable security settings for different needs</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Support for both text and file sharing</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-5 h-5 text-sky-300 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Intuitive interface for users of all technical levels</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Why Choose Safe Whisper */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-slate-800 mb-6 border-b-2 border-slate-300 pb-2">
            Why Choose Safe Whisper?
          </h2>
          <div className="bg-slate-50 rounded-lg p-6 shadow-md">
            <p className="text-slate-700 mb-4">
              In today's digital age, sending encrypted messages has become increasingly important. With the rise of cybercrime and hacking, it's more critical than ever to protect sensitive information.
            </p>
            <p className="text-slate-700 mb-4">
              Safe Whisper stands out as a trusted solution for ephemeral information sharing. Its emphasis on simplicity, security, and user empowerment makes it an ideal choice for anyone seeking to transmit sensitive data without compromising confidentiality.
            </p>
            <p className="text-slate-700">
              By integrating Safe Whisper into your communication practices, you can effectively mitigate the risks associated with sharing sensitive information through traditional channels. Our platform's emphasis on encryption, self-destruction of notes, and user-controlled security settings provides a reliable solution for maintaining the confidentiality and integrity of private data in both personal and professional contexts.
            </p>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <a href="#" className="inline-block bg-sky-600 hover:bg-sky-700 text-white font-bold py-3 px-8 rounded-lg shadow-lg transition-colors">
            Start Sending Secure Messages Now
          </a>
        </div>
      </section>
      {/* SEO-Enhanced Section for Main Page */}
      <section className="w-full max-w-6xl mx-auto py-12 px-4 mb-16">
        <div className="bg-gradient-to-r from-slate-700 to-slate-600 rounded-xl shadow-2xl overflow-hidden">
          <div className="p-8">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              The Secure Way to <span className="text-sky-400">Share Sensitive Information Online</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-8">
              <div>
                <p className="text-gray-300 text-lg mb-6 leading-relaxed">
                  Create a one time secure link, share your sensitive data, and rest assured knowing that once the link is accessed, it expires immediately, leaving no trace. Safe Whisper is designed to keep confidential information out of email and conventional messaging apps.
                </p>
                <p className="text-gray-300 text-lg leading-relaxed">
                  By encrypting and generating a short-lived link that automatically destructs after being read, we ensure your private messages remain private — exactly as they should be.
                </p>
              </div>

              <div className="bg-slate-800 rounded-lg p-6">
                <h3 className="text-xl font-bold text-white mb-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-sky-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                  Perfect For Sharing
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-sky-400 mt-1 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-300">Passwords and login credentials</span>
                  </li>
                  <li className="flex items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-sky-400 mt-1 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-300">Credit card and financial details</span>
                  </li>
                  <li className="flex items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-sky-400 mt-1 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-300">Private links and confidential URLs</span>
                  </li>
                  <li className="flex items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-sky-400 mt-1 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-300">Secret messages that need to stay private</span>
                  </li>
                  <li className="flex items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-sky-400 mt-1 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-300">Confidential business information</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* How it Works - Compact Version */}
            <div className="bg-slate-800 rounded-lg p-6 mt-8">
              <h3 className="text-xl font-bold text-white mb-6 text-center">
                How Our Secure Links Work
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="text-center">
                  <div className="bg-slate-700 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                    <span className="text-sky-400 text-2xl font-bold">1</span>
                  </div>
                  <h4 className="text-white font-semibold mb-2">Create</h4>
                  <p className="text-gray-400 text-sm">Type your sensitive information and encrypt it with our secure system</p>
                </div>

                <div className="text-center">
                  <div className="bg-slate-700 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                    <span className="text-sky-400 text-2xl font-bold">2</span>
                  </div>
                  <h4 className="text-white font-semibold mb-2">Share</h4>
                  <p className="text-gray-400 text-sm">Send the encrypted, self-destructing link to your recipient</p>
                </div>

                <div className="text-center">
                  <div className="bg-slate-700 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                    <span className="text-sky-400 text-2xl font-bold">3</span>
                  </div>
                  <h4 className="text-white font-semibold mb-2">Destruct</h4>
                  <p className="text-gray-400 text-sm">The link automatically destructs after being read, leaving no trace</p>
                </div>
              </div>
            </div>

            {/* Call to Action */}
            <div className="mt-10 text-center">
              <h3 className="text-2xl font-bold text-white mb-6">
                Ready to Send Your First Secure Message?
              </h3>
              <button
                  className="bg-sky-500 hover:bg-sky-600 rounded-full py-3 px-8 text-white font-medium text-lg w-80 transition-all duration-300 flex items-center justify-center gap-2 shadow-lg mx-auto"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
                Create Encrypted Link
              </button>
            </div>
          </div>
        </div>

        {/* FAQ Section with SEO Keywords */}
        <div className="mt-16">
          <h2 className="text-3xl font-bold text-white mb-8 text-center">
            Frequently Asked Questions
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-slate-700 rounded-lg p-6">
              <h3 className="text-xl font-bold text-white mb-3">How secure is your encrypted messaging?</h3>
              <p className="text-gray-300">
                Our service uses end-to-end encryption with AES-256 standards. Your sensitive information is encrypted before it leaves your device, ensuring that even we cannot access the contents of your secret messages.
              </p>
            </div>

            <div className="bg-slate-700 rounded-lg p-6">
              <h3 className="text-xl font-bold text-white mb-3">Why use self-destructing links instead of email?</h3>
              <p className="text-gray-300">
                Unlike email, which can be stored indefinitely on servers, our short-lived links automatically destruct after being read, keeping confidential information truly private and preventing data leaks.
              </p>
            </div>

            <div className="bg-slate-700 rounded-lg p-6">
              <h3 className="text-xl font-bold text-white mb-3">Can I share any type of sensitive information?</h3>
              <p className="text-gray-300">
                Yes, you can securely share passwords, credit card details, private links, or any confidential text message. Our encryption ensures your sensitive data remains protected throughout transmission.
              </p>
            </div>

            <div className="bg-slate-700 rounded-lg p-6">
              <h3 className="text-xl font-bold text-white mb-3">How long do the secure links last?</h3>
              <p className="text-gray-300">
                Our encrypted links are designed to self-destruct immediately after being read. This ensures your secret message or private link cannot be accessed again, even if the URL is shared.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};
export default MainPage;
