import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import logo from "../assets/logo-square.png";

const Container = ({ children, className }) => {
    return (
        <div className="min-h-screen flex flex-col bg-secondaryColor">
            <Navbar />
            <main className={`flex-grow flex flex-col items-center w-full ${className || ""}`}>
                <Link to="/" className="mt-4 mb-6 lg:hidden">
                    <img src={logo} alt="Safe Whisper" className="h-16 w-auto" />
                </Link>
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Container;