import React from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import MainPage from "./pages/MainPage";
import MessagePage from "./pages/MessagePage";
import FeaturesPage from "./pages/FeaturesPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import CookiesPage from "./pages/CookiesPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
    return (
        <>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/cookies" element={<CookiesPage />} />
            <Route path="/:urlId" element={<MessagePage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        </>
    );
}

export default App;