import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api/config";
import { FaRegCopy, FaLock, FaEye } from "react-icons/fa";
import Container from "../components/Container";

const MessagePage = () => {
    const [message, setMessage] = useState("");
    const [blur, setBlur] = useState(true);
    const [copied, setCopied] = useState(false);

    const navigate = useNavigate();
    const { urlId } = useParams();

    const readContent = async () => {
        try {
            // Call the GET endpoint that decrypts and deletes the message
            const response = await api.get(`encrypt/${urlId}`);
            if (response.data && response.data.decryptedMessage) {
                setMessage(response.data.decryptedMessage);
            } else {
                setMessage("Sorry, this message was destroyed...");
            }
        } catch (err) {
            console.error("Error reading content:", err);
            setMessage("Sorry, this message was destroyed...");
        }
        setBlur(false);

        // Optionally redirect after some delay (e.g., 60 seconds)
        setTimeout(() => {
            navigate("/");
        }, 60000);
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard
            .writeText(message)
            .then(() => setCopied(true))
            .catch((error) => console.error("Error copying text:", error));
    };

    return (
        <Container>
            <section className="min-h-screen py-12 px-4">
                <div className="max-w-4xl mx-auto">
                    {/* Card container for message */}
                    <div className="bg-gradient-to-r from-slate-700 to-slate-600 rounded-xl shadow-2xl overflow-hidden">
                        {/* Card Header */}
                        <div className="p-6 border-b border-slate-500">
                            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                <div>
                                    <h1 className="text-2xl md:text-3xl font-bold text-white">Your Private Message</h1>
                                    <p className="text-gray-300 mt-2">
                                        This message will be permanently destroyed after you read it
                                    </p>
                                </div>
                                <div className="mt-4 md:mt-0">
                                    <div className="bg-red-100 text-red-800 px-4 py-1 rounded-full text-sm font-medium flex items-center">
                                        <FaLock className="mr-2" />
                                        Self-Destructing
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Card Body */}
                        <div className="p-6">
                            <div className="flex flex-col items-center">
                                {/* Message area */}
                                <div className="w-full mb-4 relative">
                                    {blur && (
                                        <div className="absolute inset-0 flex flex-col items-center justify-center z-10 bg-slate-800 bg-opacity-50 rounded-lg backdrop-blur-sm">
                                            <div className="text-center p-6 w-full">
                                                <FaEye className="text-white text-4xl mx-auto mb-4" />
                                                <p className="text-white text-lg mb-6">
                                                    This message is encrypted and will self-destruct after viewing
                                                </p>
                                                <div className="flex justify-center w-full">
                                                    <button
                                                        className="bg-sky-500 hover:bg-sky-600 rounded-full py-3 px-8 text-white font-medium text-lg transition-all duration-300 flex items-center justify-center gap-2 shadow-lg w-64"
                                                        onClick={readContent}
                                                    >
                                                        Read Message
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <textarea
                                        rows={10}
                                        readOnly
                                        className={`w-full rounded-lg border-2 border-slate-400 bg-slate-800 text-white p-4 focus:outline-none`}
                                        value={message}
                                    />
                                </div>

                                {/* Actions area - visible only after unblurring */}
                                {!blur && (
                                    <div className="flex flex-col items-center">
                                        <div className="flex items-center gap-3 mb-4">
                                            <p className="text-gray-300 text-sm">
                                                This message will disappear in 60 seconds
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <button
                                                onClick={handleCopyToClipboard}
                                                className="bg-white hover:bg-gray-100 text-slate-800 px-6 py-2 rounded-lg font-medium flex items-center gap-2 transition-colors"
                                            >
                                                <FaRegCopy className="text-lg" />
                                                {copied ? "Copied!" : "Copy Text"}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Card Footer */}
                        <div className="bg-slate-800 p-4 text-center">
                            <p className="text-gray-400 text-sm">
                                For security reasons, this message can only be viewed once
                            </p>
                        </div>
                    </div>

                    {/* Security indicator */}
                    <div className="mt-8 text-center">
                        <div className="inline-flex items-center bg-gray-700 px-4 py-2 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                            <span className="text-white">End-to-End Encrypted</span>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    );
};

export default MessagePage;